import { api } from "~/utils/api";
import { type NextPage } from "next";
import Head from "next/head";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";

const Login: NextPage = () => {
  const { data: isSignedIn } = api.auth.status.useQuery();
  const router = useRouter();
  const preAuthMutation = api.auth.preAuth.useMutation();

  useEffect(() => {
    if (isSignedIn) {
      router.push("/").catch((error) => {
        console.error("Error during navigation:", error);
      });
    }
  }, []);

  const SignInForm = () => {
    const router = useRouter();
    const [error, setError] = useState("");

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      if (event.target instanceof HTMLFormElement) {
        const formData = new FormData(event.target);
        const emailAddress = formData.get("email");
        const password = formData.get("password");

        if (!emailAddress || !password) return;

        const result = await preAuthMutation.mutateAsync({
          email: emailAddress.toString(),
          password: password.toString(),
          appType: "developer",
        });

        // If signIn is successful, redirect to the MFA page
        if (result.mfaType === "totp-app") {
          await router.push("/multi-factor/authentication").catch((error) => {
            console.error("Error during navigation:", error);
          });
        } else {
          await router.push("/multi-factor/no-mfa").catch((error) => {
            console.error("Error during navigation:", error);
          });
        }
      }
    };

    return (
      <form
        onSubmit={async (event) => await handleSubmit(event)}
        className="rounded-md border border-gray-600 bg-gray-900 p-12 shadow-lg"
      >
        <div className="mb-3 flex flex-col">
          <label
            htmlFor="email"
            className="mb-2 font-sans text-base font-medium text-white"
          >
            Email
          </label>
          <input
            id="email"
            name="email"
            type="email"
            className="rounded-md border border-gray-600 bg-gray-700 text-white p-1"
            required
          />
        </div>
        <div className="mb-3 flex flex-col">
          <label
            htmlFor="password"
            className="mb-2 font-sans text-base font-medium text-white"
          >
            Password
          </label>
          <input
            id="password"
            name="password"
            type="password"
            className="rounded-md border border-gray-600 bg-gray-700 text-white p-1"
            required
          />
        </div>
        <button className="mb-4 mt-4 w-full rounded-md bg-green-600 p-2 font-sans text-base font-medium text-white transition hover:bg-green-700">
          Sign in
        </button>
        <Link
          href="/forgot-password"
          className="mb-2 font-sans text-base font-medium text-white"
        >
          Forgot password?
        </Link>
      </form>
    );
  };

  return (
    <div>
      <Head>
        <title>Ceritas API Login</title>
        <meta name="description" content="API documentation and tutorials for Ceritas API" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <main className="h-screen w-full bg-cover bg-center bg-gray-700">
        <div className="flex h-full w-full flex-row justify-center align-middle">
          <div className="flex h-full w-128 flex-col justify-center align-middle">
            <div className="flex flex-row justify-center align-middle">
              <Image
                className="mb-8"
                src={"/ceritas-logo-white.svg"}
                alt="logo"
                height={33}
                width={154}
              />
            </div>
            <div>
              <SignInForm />
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Login;
